import React from 'react';
import { Box, Typography } from '@mui/material';
import './NotFound.css';

const NotFound: React.FC = () => {
  return (
    <Box className="not-found-page">
      <Typography variant="h1" className="not-found-title">404</Typography>
      <Typography variant="h6" className="not-found-subtitle">Oops! You must be lost.</Typography>
    </Box>
  );
};

export default NotFound;
