import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Login from './components/Login';
import Dashboard from './components/Dashboard';
import SecureFileUpload from './components/SecureFileUpload';
import AdminPage from './components/AdminPage';
import DarkWebMonitoring from './components/DarkWebMonitoring'; // Import DarkWebMonitoring
import RansomwareReportingPage from './components/RansomwareReportingPage'; // Import RansomwareReportingPage
import { AuthProvider } from './context/AuthContext';
import PrivateRoute from './components/PrivateRoute';
import NotFound from './components/NotFound';

const App = () => {
  return (
    <AuthProvider>
      <Router>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/dashboard" element={<PrivateRoute element={<Dashboard />} />} />
          <Route path="/securefileupload" element={<PrivateRoute element={<SecureFileUpload />} />} />
          <Route path="/admin" element={<PrivateRoute element={<AdminPage />} />} />
          <Route path="/darkwebmonitoring" element={<PrivateRoute element={<DarkWebMonitoring />} />} />
          <Route path="/ransomwarereporting" element={<PrivateRoute element={<RansomwareReportingPage />} />} /> // Add RansomwareReportingPage route
          <Route path="*" element={<NotFound />} />
        </Routes>
      </Router>
    </AuthProvider>
  );
};

export default App;