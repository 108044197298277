// firebase.ts

import { initializeApp } from 'firebase/app';
import { getAuth, GoogleAuthProvider } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
import { getStorage } from 'firebase/storage';

const firebaseConfig = {
  apiKey: "AIzaSyCXkOoNWPW7awyHk2jT5_rbMiP0qdayQ1A",
  authDomain: "sendsec-d834c.firebaseapp.com",
  databaseURL: "https://sendsec-d834c-default-rtdb.firebaseio.com",
  projectId: "sendsec-d834c",
  storageBucket: "sendsec-d834c.appspot.com",
  messagingSenderId: "738275385966",
  appId: "1:738275385966:web:a36fe207f42b8ff6377c7f",
  measurementId: "G-5ZX3TEWXDX"
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);
const storage = getStorage(app);
const googleProvider = new GoogleAuthProvider();

export { auth, db, storage, googleProvider };
