import React from 'react';
import { AppBar, Toolbar, Typography, Box } from '@mui/material';
import logo2 from '../images/logo2.png';

const Footer: React.FC = () => {
  return (
    <AppBar position="fixed" color="default" className="footer" sx={{ top: 'auto', bottom: 0 }}>
      <Toolbar>
        <Box sx={{ flexGrow: 1 }} />
        <img src={logo2} alt="Triad Red Logo" className="footer-logo" />
        <Typography variant="body1" className="footer-text">Triad Red</Typography>
        <Box sx={{ flexGrow: 1 }} />
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Typography variant="body2" sx={{ marginRight: 2 }}>v.1.0.1</Typography>
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default Footer;
