import React, { useState, useEffect } from 'react';
import {
  Typography, TextField, Button, Card, CardContent, Grid, IconButton, MenuItem, Select, FormControl, InputLabel, Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow
} from '@mui/material';
import { Add as AddIcon, Delete as DeleteIcon } from '@mui/icons-material';
import { collection, addDoc, getDocs, deleteDoc, doc, Timestamp } from 'firebase/firestore';
import { db } from '../firebase';
import { ApexOptions } from 'apexcharts';
import Chart from 'react-apexcharts';
import TopBar from './TopBar';
import Footer from './Footer';
import Menu from './Menu';
import './AdminPage.css';

interface Client {
  id: string;
  name: string;
  email: string;
  services: string[];
  addedOn: Date;
}

const AdminPage: React.FC = () => {
  const [clients, setClients] = useState<Client[]>([]);
  const [newClient, setNewClient] = useState({ name: '', email: '', services: [] as string[] });
  const [selectedServices, setSelectedServices] = useState<string[]>([]);
  const [drawerOpen, setDrawerOpen] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      const clientsData: Client[] = [];
      const querySnapshot = await getDocs(collection(db, 'clients'));
      querySnapshot.forEach((doc) => {
        const data = doc.data() as Client;
        clientsData.push({ ...data, id: doc.id });
      });
      setClients(clientsData);
    };

    fetchData();
  }, []);

  const handleServiceChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setSelectedServices(event.target.value as string[]);
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNewClient({ ...newClient, [e.target.name]: e.target.value });
  };

  const handleAddClient = async () => {
    if (newClient.name && newClient.email) {
      const clientData = {
        ...newClient,
        services: selectedServices,
        addedOn: Timestamp.now().toDate(),
      };
      const docRef = await addDoc(collection(db, 'clients'), clientData);
      setClients([...clients, { id: docRef.id, ...clientData }]);
      setNewClient({ name: '', email: '', services: [] });
      setSelectedServices([]);
    }
  };

  const handleDeleteClient = async (id: string) => {
    await deleteDoc(doc(db, 'clients', id));
    setClients(clients.filter(client => client.id !== id));
  };

  const servicesColors: { [key: string]: string } = {
    'Web Development': '#FF4560',
    'Vulnerability Management': '#008FFB',
    'Application Penetration Testing': '#00E396',
    'Internal Network Penetration Testing': '#775DD0',
    'External Network Penetration Testing': '#FEB019',
    'WiFi Vulnerability Assessment': '#FF4560',
    'Social Engineering Operations': '#008FFB',
    'Static Code Analysis': '#00E396',
    'Consulting': '#775DD0',
    'Active Directory Attack Path Audits': '#FEB019',
    'Executive PII Reporting': '#FF4560',
    'Vulnerability Scanning': '#008FFB',
    'Phishing Campaigns': '#00E396',
    'Dark Web Reconnaissance': '#775DD0'
  };

  const chartOptions: ApexOptions = {
    chart: {
      type: 'bar',
      stacked: true,
    },
    xaxis: {
      categories: clients.map(client => client.name),
    },
    colors: Object.values(servicesColors),
    tooltip: {
      y: {
        formatter: function (val: number, opts: any) {
          return `${opts.w.globals.seriesNames[opts.seriesIndex]}: ${val} services`;
        },
      },
    },
    legend: {
      position: 'bottom',
      horizontalAlign: 'center',
      itemMargin: {
        vertical: 8
      }
    },
  };

  const series = Object.keys(servicesColors).map(service => ({
    name: service,
    data: clients.map(client => client.services.includes(service) ? 1 : 0)
  }));

  return (
    <Box className="admin-page" sx={{ bgcolor: 'black' }}>
      <TopBar setDrawerOpen={setDrawerOpen} />
      <Menu drawerOpen={drawerOpen} setDrawerOpen={setDrawerOpen} />
      <Box className="content">
        <Grid container spacing={3}>
          <Grid item xs={12} md={4}>
            <Card className="form-card">
              <CardContent>
                <Typography variant="h5" className="card-title">
                  Add New Client
                </Typography>
                <TextField
                  label="Name"
                  name="name"
                  value={newClient.name}
                  onChange={handleInputChange}
                  fullWidth
                  className="textfield-input"
                />
                <TextField
                  label="Email"
                  name="email"
                  value={newClient.email}
                  onChange={handleInputChange}
                  fullWidth
                  className="textfield-input"
                />
                <FormControl fullWidth className="textfield-input">
                  <InputLabel>Services</InputLabel>
                  <Select
                    multiple
                    value={selectedServices}
                    onChange={(event) => handleServiceChange(event as React.ChangeEvent<{ value: unknown }>)}
                    renderValue={(selected) => (selected as string[]).join(', ')}
                  >
                    {Object.keys(servicesColors).map((service) => (
                      <MenuItem key={service} value={service}>
                        {service}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <Button
                  variant="contained"
                  color="primary"
                  startIcon={<AddIcon />}
                  onClick={handleAddClient}
                  className="add-client-button"
                >
                  Add Client
                </Button>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} md={8}>
            <Card className="chart-card">
              <CardContent>
                <Typography variant="h5" className="card-title">
                  Services Overview
                </Typography>
                <Chart
                  options={chartOptions}
                  series={series}
                  type="bar"
                  height={350}
                />
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12}>
            <Card className="client-card">
              <CardContent>
                <Typography variant="h5" className="card-title">
                  Clients
                </Typography>
                <Box className="client-table-wrapper">
                  <TableContainer className="client-table-container">
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell className="table-cell">Name</TableCell>
                          <TableCell className="table-cell">Email</TableCell>
                          <TableCell className="table-cell">Services</TableCell>
                          <TableCell className="table-cell">Added On</TableCell>
                          <TableCell className="table-cell">Actions</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {clients.map((client) => (
                          <TableRow key={client.id}>
                            <TableCell className="table-cell">{client.name}</TableCell>
                            <TableCell className="table-cell">{client.email}</TableCell>
                            <TableCell className="table-cell">{client.services.join(', ')}</TableCell>
                            <TableCell className="table-cell">{new Date(client.addedOn).toLocaleDateString()}</TableCell>
                            <TableCell className="table-cell">
                              <IconButton color="secondary" onClick={() => handleDeleteClient(client.id)}>
                                <DeleteIcon />
                              </IconButton>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Box>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Box>
      <Footer />
    </Box>
  );
};

export default AdminPage;
