import React, { useState, useEffect } from 'react';
import { Box, Typography, Menu, MenuItem } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { auth } from '../firebase';
import TopBar from './TopBar';
import Footer from './Footer';
import MenuComponent from './Menu';
import logo from '../images/logo.png';
import './Dashboard.css';

const quotes = [
  "As John Chambers famously said, ‘There are only two types of organizations: Those that have been hacked and those that don’t know it yet!’",
  "It’s not a question of if, but when.",
  "It takes 20 years to build a reputation and few minutes of cyber incident to ruin it.",
  "If you can’t afford security, you can’t afford a breach.",
  "Give a man an 0day and he’ll have access for a day, teach a man to phish and he’ll have access for life."
];

const authors = [
  "— John Chambers",
  "— Unknown",
  "— Stephane Nappo",
  "— Unknown",
  "— Unknown"
];

const Dashboard: React.FC = () => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [userName, setUserName] = useState<string>('');
  const navigate = useNavigate();

  const randomIndex = Math.floor(Math.random() * quotes.length);

  useEffect(() => {
    const email = auth.currentUser?.email || '';
    const username = email.split('@')[0];
    setUserName(username);
  }, []);

  const handleMenuClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    auth.signOut().then(() => {
      navigate('/');
    });
    setAnchorEl(null);
  };

  return (
    <Box className="dashboard">
      <TopBar setDrawerOpen={setDrawerOpen} />
      <MenuComponent drawerOpen={drawerOpen} setDrawerOpen={setDrawerOpen} />
      <Box component="main" className="content-container">
        <img src={logo} alt="Company Logo" className="main-logo" />
        <Typography variant="h3" className="quote" gutterBottom>
          {quotes[randomIndex]}
        </Typography>
        <Typography variant="h6" className="quote-author" gutterBottom>
          {authors[randomIndex]}
        </Typography>
      </Box>
      <Footer />
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
      >
        <MenuItem onClick={handleLogout}>Logout</MenuItem>
      </Menu>
    </Box>
  );
};

export default Dashboard;
