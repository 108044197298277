import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Typography, Card, CardContent, Box, CircularProgress } from '@mui/material';
import './RansomwareReporting.css';

interface RansomwareIncident {
  victim_name: string;
  date_added: string;
  country: string;
  industry: string;
}

const RansomwareReportingPage: React.FC = () => {
  const [incidents, setIncidents] = useState<RansomwareIncident[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchRansomwareIncidents = async () => {
      try {
        const response = await axios.get('https://api.ransomware.live/recentvictims', {
          headers: {
            accept: 'application/json',
          },
        });
        setIncidents(response.data);
      } catch (error) {
        console.error('Error fetching ransomware incidents:', error);
        setError('Failed to load ransomware incidents. Please try again later.');
      } finally {
        setLoading(false);
      }
    };

    fetchRansomwareIncidents();
  }, []);

  return (
    <Box className="ransomware-reporting-page" sx={{ bgcolor: 'black', minHeight: '100vh', padding: '20px' }}>
      <Typography variant="h4" className="title" gutterBottom>
        Ransomware Incident Reports
      </Typography>
      {loading ? (
        <Box display="flex" justifyContent="center" alignItems="center" height="50vh">
          <CircularProgress />
        </Box>
      ) : error ? (
        <Typography color="error" align="center">{error}</Typography>
      ) : incidents.length > 0 ? (
        <Box className="incident-list">
          {incidents.map((incident, index) => (
            <Card key={index} className="incident-card">
              <CardContent>
                <Typography variant="h6">Victim Name: {incident.victim_name}</Typography>
                <Typography>Date Added: {new Date(incident.date_added).toLocaleDateString()}</Typography>
                <Typography>Country: {incident.country}</Typography>
                <Typography>Industry: {incident.industry}</Typography>
              </CardContent>
            </Card>
          ))}
        </Box>
      ) : (
        <Typography align="center">No data available. Please check back later.</Typography>
      )}
    </Box>
  );
};

export default RansomwareReportingPage;