import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';
import { FcGoogle } from 'react-icons/fc';
import logo from '../images/logo.png';
import './Login.css';

const Login: React.FC = () => {
  const navigate = useNavigate();
  const { currentUser, login } = useAuth();

  useEffect(() => {
    if (currentUser) {
      navigate('/dashboard');
    }
  }, [currentUser, navigate]);

  return (
    <div className="login-container">
      <div className="paper">
        <img src={logo} alt="Shield Vault Logo" className="logo" />
        <div className="shield-vault">
          <div className="text" style={{ color: '#B0B0B0' }}>Shield Vault</div>
        </div>
        <div className="slogan">- powered by Triad Red -</div>
        <div className="login-buttons">
          <button className="login-button google" onClick={login}>
            <FcGoogle className="button-icon" />
            Login with Google
          </button>
        </div>
      </div>
    </div>
  );
};

export default Login;
