import React, { useState } from 'react';
import { Box, Button, TextField, Typography, Card, CardContent, Grid, FormControlLabel, Checkbox } from '@mui/material';
import axios from 'axios';
import Menu from './Menu';
import TopBar from './TopBar';
import Footer from './Footer';
import './DarkWebMonitoring.css';
import { exportToCsv } from './exportToCsv';

const SNUSBASE_API_KEY = 'sb6ft38do0h57wlb95bxwrw3y0zy8y'; // Replace with your Snusbase API key

interface SnusbaseResult {
  took: number;
  size: number;
  results: Record<string, any[]>; // Define results as a dictionary with string keys and array of any values
}

const DarkWebMonitoring: React.FC = () => {
  const [query, setQuery] = useState('');
  const [searchType, setSearchType] = useState('email'); // Default search type
  const [wildcard, setWildcard] = useState(false);
  const [snusbaseResult, setSnusbaseResult] = useState<SnusbaseResult | null>(null);
  const [drawerOpen, setDrawerOpen] = useState(false);

  const handleSearchSnusbase = async () => {
    try {
      const response = await axios.post('https://api.snusbase.com/data/search', {
        terms: [query],
        types: [searchType],
        wildcard: wildcard
      }, {
        headers: {
          'Auth': SNUSBASE_API_KEY,
          'Content-Type': 'application/json'
        }
      });
      setSnusbaseResult(response.data);
    } catch (error) {
      console.error('Error fetching Snusbase data:', error);
    }
  };

  const handleExportToCsv = () => {
    if (!snusbaseResult || !snusbaseResult.results || !Object.keys(snusbaseResult.results).length) {
      console.error('No results to export');
      return;
    }
  
    const formattedResults: any[] = [];
    Object.keys(snusbaseResult.results).forEach(key => {
      const entries = snusbaseResult.results[key];
      entries.forEach(entry => {
        formattedResults.push({
          source: key,
          email: entry.email || '',
          username: entry.username || '',
          password: entry.password || '',
          hash: entry.hash || '',
          name: entry.name || '',
          ...entry
        });
      });
    });
  
    exportToCsv(formattedResults, 'darkweb_results.csv');
  };

  const generateSummary = () => {
    if (!snusbaseResult) return null;

    let emailCount = 0;
    let usernameCount = 0;
    let passwordCount = 0;
    let hashCount = 0;
    let nameCount = 0;

    Object.values(snusbaseResult.results).forEach((entries) => {
      entries.forEach((entry) => {
        if (entry.email) emailCount++;
        if (entry.username) usernameCount++;
        if (entry.password) passwordCount++;
        if (entry.hash) hashCount++;
        if (entry.name) nameCount++;
      });
    });

    return (
      <Box style={{ marginBottom: '20px', color: '#fff' }}>
        <Typography variant="body1">
          Metrics:
        </Typography>
        <Typography variant="body2">Emails: {emailCount}</Typography>
        <Typography variant="body2">Usernames: {usernameCount}</Typography>
        <Typography variant="body2">Passwords: {passwordCount}</Typography>
        <Typography variant="body2">Hashes: {hashCount}</Typography>
        <Typography variant="body2">Names: {nameCount}</Typography>
      </Box>
    );
  };

  return (
    <Box className="dark-web-monitoring">
      <TopBar setDrawerOpen={setDrawerOpen} />
      <Menu drawerOpen={drawerOpen} setDrawerOpen={setDrawerOpen} />
      <Box className="content" style={{ paddingBottom: '150px', overflowY: 'auto', maxHeight: 'calc(100vh - 250px)' }}>
        <Typography variant="h4" gutterBottom>
          Dark Web Monitoring
        </Typography>
        <Box className="search-bar">
          <TextField
            label="Search now"
            value={query}
            onChange={(e) => setQuery(e.target.value)}
            fullWidth
            variant="outlined"
            className="search-input"
          />
          <Button
            variant="contained"
            color="primary"
            onClick={handleSearchSnusbase}
            className="search-button"
          >
            Search
          </Button>
        </Box>
        <Box className="search-options">
          <Grid container spacing={2} justifyContent="center">
            {['email', 'username', 'lastip', 'password', 'hash', '_domain', 'name'].map((type) => (
              <Grid item xs={6} sm={3} md={2} key={type}>
                <Button
                  variant={searchType === type ? 'contained' : 'outlined'}
                  onClick={() => setSearchType(type)}
                  className="option-button"
                >
                  {type.charAt(0).toUpperCase() + type.slice(1)}
                </Button>
              </Grid>
            ))}
          </Grid>
          <FormControlLabel
            control={
              <Checkbox
                checked={wildcard}
                onChange={(e) => setWildcard(e.target.checked)}
                color="primary"
              />
            }
            label="Enable Wildcard"
            className="wildcard-checkbox"
          />
        </Box>
        {snusbaseResult && (
          <Card className="results-card" style={{ marginTop: '20px' }}>
            <CardContent>
              <Typography variant="h6" sx={{ color: '#fff' }}>Summary of Results</Typography>
              {generateSummary()}
              <Box style={{ textAlign: 'right', marginTop: '20px' }}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleExportToCsv}
                >
                  Export to CSV
                </Button>
              </Box>
            </CardContent>
          </Card>
        )}
      </Box>
      <Footer />
    </Box>
  );
};

export default DarkWebMonitoring;