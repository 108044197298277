import React from 'react';
import { AppBar, Toolbar, IconButton, Typography, Avatar, Box, Menu, MenuItem } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { useNavigate } from 'react-router-dom';
import { auth } from '../firebase';

interface TopBarProps {
  setDrawerOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const TopBar: React.FC<TopBarProps> = ({ setDrawerOpen }) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [userName, setUserName] = React.useState<string>('');
  const navigate = useNavigate();

  React.useEffect(() => {
    const email = auth.currentUser?.email || '';
    const username = email.split('@')[0];
    setUserName(username);
  }, []);

  const handleMenuClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    auth.signOut().then(() => {
      navigate('/');
    });
    setAnchorEl(null);
  };

  return (
    <AppBar position="fixed" color="default" className="appbar">
      <Toolbar>
        <IconButton edge="start" color="inherit" aria-label="menu" onClick={() => setDrawerOpen(true)}>
          <MenuIcon />
        </IconButton>
        <Box sx={{ flexGrow: 1 }} />
        <Typography variant="body1" sx={{ mx: 2 }}>
          Welcome: {userName}
        </Typography>
        <Avatar sx={{ cursor: 'pointer' }} onClick={handleMenuClick}>
          👨‍💻
        </Avatar>
        <Menu
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleMenuClose}
        >
          <MenuItem onClick={handleLogout}>Logout</MenuItem>
        </Menu>
      </Toolbar>
    </AppBar>
  );
};

export default TopBar;
