import React, { useState, useEffect } from 'react';
import {
  Box, Typography, FormControl, Select, InputLabel, Button, Alert, Snackbar, Menu, MenuItem
} from '@mui/material';
import { ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
import { FileDrop } from 'react-file-drop';
import { useNavigate } from 'react-router-dom';
import { auth, storage } from '../firebase';
import TopBar from './TopBar';
import Footer from './Footer';
import MenuComponent from './Menu';
import './SecureFileUpload.css';

const SecureFileUpload: React.FC = () => {
  const [files, setFiles] = useState<File[]>([]);
  const [downloadURLs, setDownloadURLs] = useState<{ url: string; name: string }[]>([]);
  const [expiry, setExpiry] = useState<string>('24h');
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [userName, setUserName] = useState<string>('');
  const [showAlert, setShowAlert] = useState(false);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const email = auth.currentUser?.email || '';
    const username = email.split('@')[0];
    setUserName(username);
  }, []);

  const handleMenuClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    auth.signOut().then(() => {
      navigate('/');
    });
    setAnchorEl(null);
  };

  const handleFileChange = (files: FileList | null) => {
    if (files) {
      setFiles(Array.from(files).slice(0, 2)); // Allow up to 2 files
    }
  };

  const handleUpload = () => {
    const urls: { url: string; name: string }[] = [];
    files.forEach((file) => {
      const storageRef = ref(storage, `files/${file.name}`);
      const uploadTask = uploadBytesResumable(storageRef, file);

      uploadTask.on('state_changed',
        () => {},
        (error) => {
          console.error(error);
        },
        () => {
          getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
            urls.push({ url: downloadURL, name: file.name });
            if (urls.length === files.length) {
              setDownloadURLs(urls);
              setShowAlert(true);
            }
          });
        }
      );
    });
  };

  const handleExpiryChange = (event: any) => {
    setExpiry(event.target.value as string);
  };

  const handleCopyLink = (url: string) => {
    navigator.clipboard.writeText(url);
    setShowSnackbar(true);
  };

  const handleCloseSnackbar = () => {
    setShowSnackbar(false);
  };

  return (
    <Box className="dashboard">
      <TopBar setDrawerOpen={setDrawerOpen} />
      <MenuComponent drawerOpen={drawerOpen} setDrawerOpen={setDrawerOpen} />
      <Box component="main" className="content-container">
        <Box className="file-upload-container">
          <Typography variant="h4" className="upload-title" sx={{ mb: 2 }}>Secure File Upload</Typography>
          <FileDrop onDrop={(files, event) => handleFileChange(files)}>
            <Box className="file-drop-box" sx={{ mb: 2 }}>
              <Typography variant="body1" className="file-drop-text">Drag'n'drop files here to upload</Typography>
              <Typography variant="body2" className="file-drop-text">Only .pdf, .doc(x), .xls(x), .ppt(x), .csv, .txt, & .zip files that are less than 50mb in size.</Typography>
              {files.length > 0 && (
                <Box sx={{ mt: 2 }}>
                  <Typography variant="body1" className="file-drop-text">Files to be uploaded:</Typography>
                  <ul>
                    {files.map((file, index) => (
                      <li key={index}>{file.name}</li>
                    ))}
                  </ul>
                </Box>
              )}
              <Button
                variant="contained"
                component="label"
                sx={{ mt: 2 }}
              >
                Select files
                <input
                  type="file"
                  hidden
                  multiple
                  onChange={(e) => handleFileChange(e.target.files)}
                />
              </Button>
            </Box>
          </FileDrop>
          <FormControl fullWidth sx={{ mb: 2 }}>
            <InputLabel>Link Expiry</InputLabel>
            <Select value={expiry} onChange={handleExpiryChange}>
              <MenuItem value="24h">24 Hours</MenuItem>
              <MenuItem value="48h">48 Hours</MenuItem>
              <MenuItem value="3d">3 Days</MenuItem>
              <MenuItem value="5d">5 Days</MenuItem>
              <MenuItem value="burn">Burn after reading</MenuItem>
            </Select>
          </FormControl>
          <Button variant="contained" color="primary" onClick={handleUpload} sx={{ mb: 2 }}>
            Upload
          </Button>
          {downloadURLs.length > 0 && (
            <Box sx={{ mt: 2 }}>
              <Typography variant="body1">Download Links:</Typography>
              {downloadURLs.map((file, index) => (
                <Box key={index} sx={{ mt: 1 }}>
                  <Typography variant="body2">
                    <a href={file.url} target="_blank" rel="noopener noreferrer">{file.name}</a>
                  </Typography>
                  <Button variant="outlined" sx={{ mt: 1 }} onClick={() => handleCopyLink(file.url)}>
                    Copy Link for {file.name}
                  </Button>
                </Box>
              ))}
            </Box>
          )}
          <Snackbar
            open={showSnackbar}
            autoHideDuration={3000}
            onClose={handleCloseSnackbar}
            message="Copied to clipboard"
          />
        </Box>
        <Snackbar open={showAlert} autoHideDuration={6000} onClose={() => setShowAlert(false)}>
          <Alert onClose={() => setShowAlert(false)} severity="success">
            Files uploaded successfully. Please feel free to copy the encrypted file links and share them as you please.
          </Alert>
        </Snackbar>
      </Box>
      <Footer />
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
      >
        <MenuItem onClick={handleLogout}>Logout</MenuItem>
      </Menu>
    </Box>
  );
};

export default SecureFileUpload;
